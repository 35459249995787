@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

$header-height: 56px;
$footer-height: 70px;

.content {
  height: calc(100% - $header-height - $footer-height);
  margin-top: $header-height;
  margin-bottom: $footer-height;
}

.header,
.footer {
  background-color: #0a124d;
  color: white;
}

.header {
  height: $header-height;
}
.footer {
  height: $footer-height;
}
